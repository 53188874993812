/* StylishTable.css */
.table-container {
  margin: 30px;
  font-size: 16px;
  overflow-y: auto;
  height: 500px;
}

table,
th,
td {
  border-collapse: collapse;
  border: 1px solid black;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.stylish-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  font-size: 14px; /* Small font size for headers */
  border-bottom: 2px solid #000; /* Different border style for header */
}

table tbody tr:nth-child(even) {
  background-color: #201d1d;
}

table tbody tr:nth-child(odd) {
  background-color: #373232;
}

table tbody tr:hover {
  background-color: #0b0b0b;
}

table thead {
  background-color: #000000;
}
