@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

.proceed-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  font-family: "Roboto", sans-serif; /* Use the 'Roboto' font or a fallback font */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}

.proceed-button:hover {
  background-color: #0056b3;
  color: #fff;
}

.proceed-button:focus {
  outline: none;
}

/* Styles for disabled button */
.proceed-button[disabled] {
  background-color: #ccc; /* Use a different color for disabled state */
  cursor: not-allowed; /* Change cursor to "not-allowed" */
  pointer-events: none; /* Disable pointer events */
}

/* Styles for disabled button on hover (override) */
.proceed-button[disabled]:hover {
  background-color: #ccc; /* Use the same disabled color on hover */
  color: #fff; /* Set text color for consistency */
}

.upload-button {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  font-size: 8px;
  font-family: "Roboto", sans-serif; /* Use the 'Roboto' font or a fallback font */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}

.upload-button:hover {
  background-color: #0056b3;
  color: #fff;
}

.upload-button:focus {
  outline: none;
}

/* Styles for disabled button */
.upload-button[disabled] {
  background-color: #ccc; /* Use a different color for disabled state */
  cursor: not-allowed; /* Change cursor to "not-allowed" */
  pointer-events: none; /* Disable pointer events */
}

/* Styles for disabled button on hover (override) */
.upload-button[disabled]:hover {
  background-color: #ccc; /* Use the same disabled color on hover */
  color: #fff; /* Set text color for consistency */
}

.flex-container {
  display: flex;
  justify-content:center; /* Distribute items evenly */
  margin-top: 10px;
  margin-bottom: 10px;
}

.input {
  margin: 15px; /* Add spacing between dropdowns */
}
.flex-container-ver {
  display: flex;
  margin: 15px; /* Add spacing between dropdowns */
  flex-direction: column; /* Arrange items vertically */
  justify-content: center; /* Vertically center items within the container */
  align-items: center;
}
.input_ver {
  margin-bottom: 5px;
  flex-direction: column; /* Arrange labels and inputs vertically */
}
/* Style for labels (optional) */
label {
  font-size: small;
  margin-right: 10px;
}

.file-name-input {
  display: none;
}

.csv-input:hover {
  cursor: pointer;
}
